import { Autocomplete, Box, Button, FormControl, Input, InputLabel, MenuItem, TableFooter, Select, Stack, Table, TablePagination, Typography } from '@mui/material'
import React, { useRef, useEffect, useState } from 'react'
import { Download, FilterAlt } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles'
import { useAtom, useAtomValue } from 'jotai';
import axios from 'axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { hasPermission, showConfirmationToast, convertMoney, PAYMENT_METHOD, getOrderId } from '../../../../utils/utils';
import * as XLSX from 'xlsx'
import { authAtom, tokenAtom } from '../../../../actions';
import PaymentItem from '../../Payments/components/PaymentItem';
import ConfirmPaymentModal from '../../Payments/modals/ConfirmPaymentModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const PaymentTab = ({ username: lead_username }) => {
    const [total, setTotal] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)
    const [payments, setPayments] = useState([])
    const [token] = useAtom(tokenAtom)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [method, setMethod] = useState(0)
    const [confirmPaymentModal, setConfirmPaymentModal] = useState(null)
    const authUser = useAtomValue(authAtom)
    const toastId = useRef(null)

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Token ${token}`

            axios.post('/booking/payments/payment_list/', {
                offset: page * rowsPerPage, limit: rowsPerPage,
                keyword: lead_username.replace(/#/g, ''),
                method: method > 0 ? method : null,
                startDate: dayjs().subtract(3, 'years').startOf('day').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }, {})
                .then((response) => {
                    if (response && response.data) {
                        setPayments(response.data.payments)
                        setTotal(response.data.total)
                        setTotalPayment(response.data.totalPayment ?? 0)
                    } else {
                        setPayments([])
                        setTotal(0)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setPayments([])
                    setTotal(0)
                })
        }
    }, [token, page, rowsPerPage, lead_username, method])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onConfirmPayment = (payment) => {
        axios.post('/booking/payments/confirm_payment/', {
            payment_id: payment.id, lead_order: payment.lead_order, payment_date: payment.payment_date,
            amount: payment.amount, note: payment.note,
            payment_type: payment.payment_type, payment_method: payment.payment_method
        })
            .then((response) => {
                if (response && response.data) {
                    const filter = payments.filter((item) => item.id === payment.id)
                    if (filter && filter.length > 0) {
                        const index = payments.indexOf(filter[0])
                        const newPayments = payments
                        newPayments.splice(index, 1, response.data)
                        setPayments(newPayments)
                    }
                    toast.success('Xác nhận thành công')
                    setConfirmPaymentModal(null)
                } else {
                    toast.error('Cập nhật thất bại')
                }
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onDownload = () => {
        showConfirmationToast(`Chắc chắn tải về danh sách thanh toán của khách hàng ${lead_username}?`,
            () => {
                setTimeout(() => {
                    toastId.current = toast.loading("Đang tải dữ liệu")
                    axios.post('/booking/payments/download_payment_list/', {
                        keyword: lead_username.replace(/#/g, ''),
                        method: method > 0 ? method : null,
                        startDate: dayjs().subtract(3, 'years').startOf('day').format('YYYY-MM-DD HH:mm:ss'), endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
                    }, {})
                        .then((response) => {
                            try {
                                if (response && response.data) {
                                    const reports = response.data.map((item) => {
                                        return ({
                                            'id': item.lead_order.user.id,
                                            'full_name': item.lead_order.user.full_name,
                                            'username': item.lead_order.user.username,
                                            'createdBy': item.created_by.full_name,
                                            'order': getOrderId(item.lead_order.id),
                                            'approvedBy': item.approved_by ? item.approved_by.full_name : '',
                                            'totalPrice': item.amount ?? 0,
                                            'method': PAYMENT_METHOD[item.payment_method],
                                            'date': dayjs(item.payment_date).format('HH:mm DD/MM/YYYY'),
                                            'note': item.note,
                                            'shop': item.lead_order.shop ? item.lead_order.shop.name : ''
                                        })
                                    })
                                    const worksheet = XLSX.utils.json_to_sheet(reports, { origin: 'A2' });
                                    const headers = ['ID', "Khách hàng", 'Số điện thoại', 'Mã đơn hàng', 'Người nhập', 'Người xác nhận', 'Số tiền thanh toán', 'Hình thức thanh toán', 'Ngày thanh toán', 'Chú thích', 'Cửa hàng']
                                    worksheet['!merges'] = [
                                        { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length } },
                                    ]
                                    XLSX.utils.sheet_add_aoa(worksheet, [[`Danh sách thanh toán của khách hàng ${lead_username}?`]], { origin: "A1" });
                                    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A2" });
                                    const workbook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                                    XLSX.writeFile(workbook, `Danh sách thanh toán.xlsx`);

                                    toast.dismiss(toastId.current)
                                    toast.success('Tải về thành công')
                                }
                            } catch (error) {
                                toast.dismiss(toastId.current)
                                toast.error('Lỗi tải về')
                            }
                        })
                        .catch((error) => {
                            console.log({ error })
                            toast.dismiss(toastId.current)
                            toast.error('Lỗi tải về')
                        })
                }, 500);
            }, () => { })
    }

    if (!(authUser && hasPermission(authUser, ['view_payment', 'write_payment', 'view_payment_all']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', maxWidth: '100%', width: '100%' }}>
            <Box sx={{ display: "flex", width: '100%', marginTop: '16px', padding: '0px' }}>
                <Paper sx={{ width: '100%', padding: '0px 0px' }}>
                    <Stack direction='row' justifyContent='flex-end' spacing='16px'>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40, 50]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {hasPermission(authUser, ['export_report_payment']) && <Button onClick={onDownload}><Download /></Button>}
                    </Stack>
                    <TableContainer component={Paper} sx={{ overflowY: 'scroll', width: '100%', overflowX: 'scroll', maxHeight: 'calc(100vh - 280px)' }}>
                        <Table stickyHeader aria-label="simple table">
                            <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>

                                <TableRow>
                                    <StyledTableCell sx={{ minWidth: { md: '120px' } }}>Khách hàng</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Người nhập</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Người xác nhận</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Đơn hàng</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Số tiền thanh toán</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Hình thức thanh toán</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Ngày thanh toán</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Chú thích</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>Cửa hàng</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}></StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell sx={{ minWidth: { md: '120px' } }} colSpan={4}>Tổng</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}>{`${convertMoney(totalPayment, true)}`}</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}></StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}></StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}></StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}></StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: { md: '120px' } }}></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((payment) => (
                                    <PaymentItem
                                        key={payment.id.toString()}
                                        payment={payment}
                                        onConfirmPayment={() => setConfirmPaymentModal(payment)}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>

            {confirmPaymentModal !== null &&
                <ConfirmPaymentModal
                    open={confirmPaymentModal !== null}
                    onAddPayment={onConfirmPayment}
                    payment={confirmPaymentModal}
                    handleClose={() => {
                        setConfirmPaymentModal(null)
                    }}
                />
            }
        </Box>
    )
}

export default PaymentTab